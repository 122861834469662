import React, { useEffect, useState } from 'react';
import './NetflixCode.css'; // Import the CSS file

const NetflixCoode = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [country, setCountry] = useState('en');
  const [showDropdown, setShowDropdown] = useState(false); // For toggling dropdown visibility

  const translations = {
    en: {
      title: "Get Your Netflix Household Code",
      instructions: 'Select <span class="highlight">"Watch Temporarily"</span> or <span class="highlight">"I\'m Travelling"</span> in mobile/tv app. After selecting, click on send email to proceed and get the code.',
      emailPlaceholder: "Enter your account email",
      subInstructions: "Please enter the Netflix email provided to you by the seller to receive the code.",
      getCode: "Get Code",
      fetching: "Fetching...",
      successMessage: "Your Code: ",
      errorMessage: "An error occurred. Please try again.",
    },
    es: {
      title: "Obtén Tu Código de Hogar de Netflix",
      instructions: 'Selecciona <span class="highlight">"Ver Temporalmente"</span> o <span class="highlight">"Estoy Viajando"</span>. Después de seleccionar, haz clic en enviar correo electrónico para proceder y obtener el código.',
      emailPlaceholder: "Ingresa tu correo electrónico de cuenta",
      subInstructions: "Por favor, ingresa el correo electrónico de Netflix proporcionado por el vendedor para recibir el código.",
      getCode: "Obtener Código",
      fetching: "Obteniendo...",
      successMessage: "Tu Código: ",
      errorMessage: "Ocurrió un error. Por favor, inténtalo de nuevo.",
    },
    ph: {
      title: "Kumuha ng Iyong Netflix Household Code",
      instructions: 'Pumili ng <span class="highlight">"Panoorin Nang Panandalian"</span> o <span class="highlight">"Nagmamagal"</span>. Pagkatapos pumili, i-click ang send email upang magpatuloy at makuha ang code.',
      emailPlaceholder: "Ilagay ang iyong account email",
      subInstructions: "Mangyaring ilagay ang Netflix email na ibinigay sa iyo ng nagbenta upang matanggap ang code.",
      getCode: "Kumuha ng Code",
      fetching: "Kumuha...",
      successMessage: "Iyong Code: ",
      errorMessage: "Nagkaroon ng error. Pakisubukang muli.",
    },
  };

  useEffect(() => {
    const storedCountry = localStorage.getItem('country');
    if (storedCountry) setCountry(storedCountry);
    else {
      getCountry().then((country) => {
        const lang = ['es', 'ph'].includes(country) ? country : 'en';
        localStorage.setItem('country', lang);
        setCountry(lang);
      });
    }
  }, []);

  async function getCountry() {
    try {
      const ipResponse = await fetch('https://api.ipify.org/?format=json');
      const ipData = await ipResponse.json();
      const infoResponse = await fetch(`https://ipinfo.io/${ipData.ip}/json`);
      const info = await infoResponse.json();
      return info.country.toLowerCase();
    } catch {
      return 'en';
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setCode('');

    try {
      const response = await fetch('https://web.loophj.com/getCodes', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();

      if (response.ok) setCode(data.code || 'No code found');
      else setError(data.message || 'Something went wrong');
    } catch {
      setError('Failed to fetch code. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleLanguageChange = (lang) => {
    localStorage.setItem('country', lang);
    setCountry(lang);
    setShowDropdown(false); // Hide the dropdown after selection
  };
  
  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };
  

  return (
    <div className="container">
      {/* Dropdown Menu */}
      <div className="dropdown-container">
        <button onClick={() => setShowDropdown(!showDropdown)} className="dropdown-btn">
          🌐 {country.toUpperCase()}
        </button>
        {showDropdown && (
          <div className="dropdown-menu">
            {Object.keys(translations).map((lang) => (
              <div key={lang} onClick={() => handleLanguageChange(lang)} className="dropdown-item">
                {lang.toUpperCase()}
              </div>
            ))}
          </div>
        )}
      </div>

      <div className="card">
        <h1 className="title">{translations[country].title}</h1>
        <p className="instructions" dangerouslySetInnerHTML={{ __html: translations[country].instructions }} />
        <form onSubmit={handleSubmit} className="form">
          <input
            type="email"
            className="input"
            placeholder={translations[country].emailPlaceholder}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <p className="sub-instructions">{translations[country].subInstructions}</p>
          <button type="submit" className="submit-btn" disabled={loading}>
            {loading ? translations[country].fetching : translations[country].getCode}
          </button>
        </form>

        
        {code && (
  <div className="success-message">
    {translations[country].successMessage}{' '}
    {isValidUrl(code) ? (
      <a href={code} target="_blank" rel="noopener noreferrer">
        Click To Update
      </a>
    ) : (
      code
    )}
  </div>
)}

        {error && <div className="error-message">{translations[country].errorMessage}</div>}
      </div>
    </div>
  );
};

export default NetflixCoode;
